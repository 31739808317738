import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import OtpResendTimer from '@components/auth/otpResendTimer';
import Button from '@components/button';
import DividerFullWidth from '@components/dividerFullWidth';
import HookFormInput from '@components/form/hookFormInput';

import { VerifyFormData, verifySchema } from '@shared/schemas';

import { IVerifyOtpProps } from './VerifyOtp';

type IProps = {
  handleVerifyMutation: (otp: string) => void;
} & Omit<IVerifyOtpProps, 'registrationEmail' | 'handleVerifyMutation'>;

const VerifyOtpForm = ({
  handleVerifyMutation,
  isSubmitting,
  handleResendOtp,
  error,
}: IProps) => {
  const methods = useForm<VerifyFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(verifySchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    setError,
  } = methods;

  const onSubmit: SubmitHandler<VerifyFormData> = async (data) => {
    handleVerifyMutation(data.otp);
  };

  useEffect(() => {
    if (error) {
      setError('otp', {
        type: 'manual',
        message: error.message,
      });
    }
  }, [error, setError]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <HookFormInput
          label="OTP"
          id="otp"
          register={register('otp')}
          error={errors['otp']?.message}
          required
          type="number"
          autoResize
          min={0}
        />
        <DividerFullWidth className="my-6" />
        <div className="flex items-center justify-between">
          <OtpResendTimer handleResendOtp={handleResendOtp} />
          <Button className="authButton" disabled={isSubmitting}>
            Verify
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default VerifyOtpForm;
