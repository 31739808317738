'use client';

import { useState } from 'react';

import { ErrorResponse } from '@interfaces';

import useUserForgotMutation from '@api/user/auth/mutations/forgot-password/useUserForgotMutation';
import useUserLoginMutation from '@api/user/auth/mutations/login/useUserLoginMutation';
import useUserLoginOtpMutation from '@api/user/auth/mutations/login/useUserLoginOtpMutation';
import useUserRegisterConfirmMailMutation from '@api/user/auth/mutations/register/useUserRegisterConfirmMailMutation';
import useUserRequestResendOtpMutation from '@api/user/auth/mutations/register/useUserRequestResendOtpMutation';

import { EmailInputForm } from '@components/auth/forgotPassword/EmailInputForm';
import VerifyOtpForm from '@components/auth/verifyOtp/VerifyOtpForm';
import Button from '@components/button';
import DividerFullWidth from '@components/dividerFullWidth';
import GoogleCaptchaWrapper from '@components/googleCaptchaWrapper';
import Modal from '@components/modals/Modal';

import { MODAL_OPTIONS } from '@features/auth/constants';

import { Email, Party, WarningIcon } from '@shared/assets';
import { AUTH_FORGOT_PASSWORD_STEPS } from '@shared/constants/authForgotPasswordSteps';
import { AUTH_LOGIN_STEPS } from '@shared/constants/authLoginSteps';
import { AUTH_ERROR_CODES } from '@shared/constants/errors';
import { IDisclosure } from '@shared/hooks/useDisclosure';
import { LoginWithPasswordData } from '@shared/schemas';
import { USER_LOGIN, toastError, toastSuccess } from '@shared/utils';
import {
  setAccessTokenForCurrentUser,
  setRefreshTokenForCurrentUser,
} from '@shared/utils/cookies/userCookies-client';

import { RegisterForm } from '../registration/createAccount/RegisterForm';
import { IUserGGInfo, LoginForm } from './LoginForm';
import { RegisterWithGoogle } from './RegisterWithGoogle';

export const descriptionOtpModal = (email: string) => (
  <div className="font-large flex flex-col gap-6 text-neutral200">
    <p>Verified by Email</p>
    <div className="flex flex-col gap-2">
      <p>
        Please enter 6-digit OTP key we have sent you to your email address -{' '}
        <span className="text-white underline">{email}</span>
      </p>
      <div className="mb-2 flex items-start gap-2 bg-yellow900 p-2 text-yellow500">
        <WarningIcon className="mt-0.5 flex max-h-4 min-h-4 min-w-4 max-w-4" />
        <p className="font-small font-medium">
          {`If you don't see the email in your inbox, please check your spam or
          junk folder.`}
        </p>
      </div>
    </div>
  </div>
);

const UserLoginAuth = ({
  isOpen: isOpenModal,
  onClose,
}: Partial<Omit<IDisclosure, 'onToggle'>>) => {
  const [currentStep, setCurrentStep] = useState(AUTH_LOGIN_STEPS.LOGIN);
  const [credentials, setCredentials] = useState<LoginWithPasswordData>({
    email: '',
    password: '',
  });
  const [userGGInfo, setUserGGInfo] = useState<IUserGGInfo | null>(null);
  const [error, setError] = useState<ErrorResponse>();
  const [registrationEmail, setRegistrationEmail] = useState('');
  const isOpen = isOpenModal || false;

  const { mutate: userLoginOtpMutation, isPending: isSubmittingLoginOtp } =
    useUserLoginOtpMutation({
      onSuccess: () => {
        setError(undefined);
        setCurrentStep(AUTH_LOGIN_STEPS.OTP);
        toastSuccess('OTP code sent to your email');
      },
      onError: (e) => {
        if (e instanceof Error) {
          toastError(AUTH_ERROR_CODES.login[e.message] || e.message);
        } else {
          setError(e);
        }
      },
    });

  const { mutate: userLoginMutation, isPending: isSubmittingLogin } =
    useUserLoginMutation({
      onSuccess: async ({ data: { token, refreshToken } }) => {
        setError(undefined);
        setAccessTokenForCurrentUser(token);
        setRefreshTokenForCurrentUser(refreshToken);
        toastSuccess('Login successful', { description: 'Start exploring' });
        window.location.reload();
      },
      onError: (e) => {
        if (e instanceof Error) {
          toastError(AUTH_ERROR_CODES.otp[e.message] || e.message);
        } else {
          setError(e);
        }
      },
    });

  const handleLoginOtpMutation = (credentials: LoginWithPasswordData) => {
    userLoginOtpMutation(credentials);
    setCredentials(credentials);
  };

  const handleLogin = (otp: string) => {
    const loginData = { ...credentials, otp };
    userLoginMutation(loginData);
  };

  const { mutate: userForgotMutation, isPending: isSubmittingEmail } =
    useUserForgotMutation({
      onSuccess: () => setCurrentStep(AUTH_FORGOT_PASSWORD_STEPS.VERIFY_MAIL),
      onError: (e) =>
        toastError(AUTH_ERROR_CODES.forgotPassword[e.message] || e.message),
    });

  const handleSubmitEmailMutation = (email: string) => {
    userForgotMutation({ email: email });
  };

  const { mutate: userUserResendOtp } = useUserRequestResendOtpMutation({
    onSuccess: () => {
      toastSuccess('OTP code sent to your email');
    },
    onError: (e) => {
      if (e instanceof Error) {
        toastError(AUTH_ERROR_CODES.otp[e.message] || e.message);
      } else {
        setError(e);
      }
    },
  });

  const {
    mutate: userConfirmMailMutation,
    isPending: isSubmittingConfirmMail,
  } = useUserRegisterConfirmMailMutation({
    onSuccess: async ({ data: { token, refreshToken } }) => {
      setError(undefined);
      setAccessTokenForCurrentUser(token);
      setRefreshTokenForCurrentUser(refreshToken);
      setCurrentStep(MODAL_OPTIONS.CREATE_SUCCESS);
    },
    onError: (e) => {
      if (e instanceof Error) {
        toastError(AUTH_ERROR_CODES.otp[e.message] || e.message);
        toastError(e.message);
      } else {
        setError(e);
      }
    },
  });

  const handleVerifyMutation = (otp: string) => {
    userConfirmMailMutation({ email: registrationEmail, otp });
  };

  const backToLogin = () => {
    setCurrentStep(AUTH_LOGIN_STEPS.LOGIN);
  };

  switch (currentStep) {
    case AUTH_LOGIN_STEPS.LOGIN:
      return (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          modalTitle="Login"
          className="!max-w-25.5rem p-6"
        >
          <LoginForm
            handleLoginOtpMutation={handleLoginOtpMutation}
            displaySignUp
            error={error}
            isSubmitting={isSubmittingLoginOtp}
            onForgotClick={() =>
              setCurrentStep(AUTH_FORGOT_PASSWORD_STEPS.EMAIL)
            }
            onSignUpClick={() => setCurrentStep(MODAL_OPTIONS.CREATE_ACCOUNT)}
            onContinueWithGoogle={() =>
              setCurrentStep(AUTH_LOGIN_STEPS.REGISTER_WITH_GOOGLE)
            }
            setUserGGInfo={setUserGGInfo}
          />
        </Modal>
      );
    case AUTH_LOGIN_STEPS.OTP:
      return (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          modalTitle="Please verify your email to continue"
          modalDescription={descriptionOtpModal(credentials.email)}
          className="!max-w-25.5rem p-6"
        >
          <VerifyOtpForm
            handleVerifyMutation={(otp: string) => {
              handleLogin(otp);
            }}
            handleResendOtp={() => handleLoginOtpMutation(credentials)}
            error={error}
            isSubmitting={isSubmittingLogin}
          />
        </Modal>
      );
    case AUTH_LOGIN_STEPS.REGISTER_WITH_GOOGLE:
      return (
        <Modal
          isOpen={isOpen}
          onClose={() => setCurrentStep(AUTH_LOGIN_STEPS.LOGIN)}
          modalTitle="Continue with Google"
          className="!max-w-25.5rem p-6"
        >
          <RegisterWithGoogle userGGInfo={userGGInfo} />
        </Modal>
      );
    case AUTH_FORGOT_PASSWORD_STEPS.EMAIL:
      return (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          modalTitle="Forgot password"
          className="!max-w-25.5rem p-6"
        >
          <EmailInputForm
            handleSubmitEmailMutation={handleSubmitEmailMutation}
            loginRoute={USER_LOGIN}
            isSubmitting={isSubmittingEmail}
            backToLogin={backToLogin}
          />
        </Modal>
      );
    case AUTH_FORGOT_PASSWORD_STEPS.VERIFY_MAIL:
      return (
        <Modal isOpen={isOpen} onClose={onClose} className="!max-w-25.5rem p-6">
          <div className="flex flex-col gap-6">
            <div className="flexCenter size-20 rounded-bl-lg rounded-tr-lg border border-neutral700 bg-neutral900">
              <Email className="text-green500" />
            </div>
            <p className="font-xxlarge font-bold">Action required</p>
            <p className="text-neutral200">
              Please check your email for further instructions to reset your
              password.{' '}
            </p>
          </div>
        </Modal>
      );

    case MODAL_OPTIONS.CREATE_ACCOUNT:
      return (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          className="!max-w-25.5rem p-6"
          modalTitle="Create account"
        >
          <GoogleCaptchaWrapper>
            <RegisterForm
              onCreateAccount={() =>
                setCurrentStep(`register-${MODAL_OPTIONS.OTP}`)
              }
              setRegistrationEmail={setRegistrationEmail}
              backToLogin={backToLogin}
            />
          </GoogleCaptchaWrapper>
        </Modal>
      );
    case `register-${MODAL_OPTIONS.OTP}`:
      return (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          modalTitle="Please verify your email to continue"
          modalDescription={descriptionOtpModal(registrationEmail)}
          className="!max-w-25.5rem p-6"
        >
          <VerifyOtpForm
            handleVerifyMutation={handleVerifyMutation}
            handleResendOtp={() =>
              userUserResendOtp({ email: registrationEmail })
            }
            error={error}
            isSubmitting={isSubmittingConfirmMail}
          />
        </Modal>
      );
    case MODAL_OPTIONS.CREATE_SUCCESS:
      return (
        <Modal isOpen={isOpen} onClose={onClose} className="!max-w-25.5rem p-6">
          <div className="flex flex-col gap-6">
            <div className="flexCenter size-20 rounded-bl-lg rounded-tr-lg border border-neutral700 bg-neutral900">
              <Party />
            </div>
            <p className="font-xxlarge font-bold">
              Thank you for joining{' '}
              <span className="text-green500">AI TECH MARKETPLACE</span>
            </p>
            <p className="text-neutral200">Start exploring</p>
            <DividerFullWidth />
            <Button
              className="authButton"
              onClick={() => window.location.reload()}
            >
              Continue
            </Button>
          </div>
        </Modal>
      );
    default:
      null;
  }
};
export default UserLoginAuth;
